import React, {useEffect, useState} from "react";
import BreadcrumbConstructor from "components/Breadcrumb";
import {useParams} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {getUserPlantsDataById} from "utils/Requests/Plant";
import _ from "lodash";
import {NotFoundPlantAlert} from "components/Alerts";
import {useTranslation} from "react-i18next";
import {PlantSummary} from "components/Summary/PlantSummary";
import {CardMaps} from "components/Cards/CardMaps";
import {CardPlotSummary} from "components/Cards/CardPlotSummary";
import {CardPlantProductionTables} from "components/Cards/CardPlantProductionTables";
import {getIconByGlobalPlantStatus, isThermalByCaller, isWaterByCaller} from "../../utils/Types";
import config from "/app/src/config.js";

const isMobileOnly = config.mbl_version;

const PlantMobileHeader = ({...props}) => {
    const {plant} = props;

    const style = {
        fontSize: "15px",
        padding: "10px 20px",
        backgroundColor: "rgb(237 237 237 / 68%)",
        borderBottom: "1px solid #c3c2c2",
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between'
    }

    const icon = getIconByGlobalPlantStatus(props, {
        plant_daily_alert_id: plant.plant_daily_alert,
        plant_hourly_alert_id: plant.plant_hourly_alert
    })

    return (<>
        <div style={style}>
            <div>
                <div>{plant.plant_name}</div>
                <div style={{fontSize: "12px"}}>{plant.plant_town_name}</div>
            </div>
            <div>
                {icon}
            </div>
        </div>
    </>)
}

export default function PagePlant({...props}) {
    const params = useParams();
    const {t} = useTranslation();

    const [configuration, setConfiguration] = useState({
        ...props,
        elId: params.id,
        plant: {},
        isLoading: true,
        isError: false
    });

    useEffect(()=> {
        // clean up controller --> prevent crash with async function
        let isMounted = true;
        //only on first load...
        if(!configuration.isLoading) { return; }
        //get plant data...
        const requestPlantData = () => {
            //get user plants
            getUserPlantsDataById(configuration.elId, (response) => {
                //prevent crash with async function
                if (!isMounted) return null;
                setConfiguration({...configuration, plant: response.data, isLoading: false, isError: false});
            }, () => {
                //prevent crash with async function
                if (!isMounted) return null;
                setConfiguration({...configuration, plant: {}, isLoading: false, isError: true});
            });
        } //get plant data
        requestPlantData(configuration);
        //prevent crash with async function
        return () => { isMounted = false; };
    }, [configuration])

    return (
        <>
            { /* breadcrumb */ }
            <div className={`content-header row ${isMobileOnly && "row-mbl"}`}>
                { configuration.isLoading && !configuration.isError && <Skeleton className={"mt-1"}/> }
                { configuration.isError && <span className={"mt-1 ms-1"}>Error...</span>}
                { !configuration.isLoading && !configuration.isError && <>
                    { isMobileOnly
                        ? <PlantMobileHeader plant={configuration.plant} caller={props.caller} />
                        : <BreadcrumbConstructor crumbs={[
                                {id: 'dashboard'}, {id: 'plants'}, {id: props.caller}, {
                                    id: 'plant',
                                    text: configuration.plant.plant_name??t('plant.not_found_breadcrumb'),
                                    disabled: true
                                }
                        ]}/>
                    }
                </>
                }
            </div>
            <div className="content-body mb-1">
                {_.isEmpty(configuration.plant) && !configuration.isLoading && !configuration.isError && <NotFoundPlantAlert /> }
                <section id={`dashboard-${configuration.caller}-${configuration.elId}`}>
                    { /* first row */}
                    <div className={`row match-height ${isMobileOnly && "row-mbl match-height-mbl"}`}>
                        { /* second row: tables */}
                        <div className={`col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ${isMobileOnly && "col-mbl"}`}>
                            <CardPlotSummary caller={configuration.caller} plantId={configuration.elId}/>
                        </div>
                        { /* second row: plot */}
                        <div className={`col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 ${isMobileOnly && "col-mbl"}`}>
                            <CardPlantProductionTables caller={configuration.caller} plantId={configuration.elId}
                                                       tableProps={{
                                                           fixedHeader: true,
                                                           fixedHeaderScrollHeight: (isThermalByCaller(configuration) || isWaterByCaller(configuration)) ? 296 : 550,
                                                           defaultSortAsc: false
                                                       }}/>
                        </div>
                    </div>
                    { /* second row */}
                    <div className={`row match-height ${isMobileOnly && "row-mbl match-height-mbl"}`}>
                        { /* first row: second group */}
                        { /* first row left group: plants and sync */}
                        <div className={`col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ${isMobileOnly && "col-mbl"}`}>
                            <PlantSummary {...configuration} data={configuration.plant} type={1}/>
                        </div>
                        <div className={`col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 ${isMobileOnly && "col-mbl"}`}>
                            <PlantSummary {...configuration} data={configuration.plant} type={2}/>
                        </div>
                        <div className={`col-xxl-6 col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12 ${isMobileOnly && "col-mbl"}`}>
                            <CardMaps {...configuration} coordinates={configuration.plant.coordinates}/>
                        </div>
                    </div>
                </section>
            </div>
        </>);
};